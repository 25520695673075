
const dismissNotification = (() =>{

	let $notifications = $('.notification');
	let $close = $('.notification').find('.close');


	const init = () => {

		$close.click( dismiss );

	};

	const dismiss = (e) =>{

		let $target = $(e.target);
		$target.parent().parent().parent().parent().remove()

	}

	init();

})();

export { dismissNotification };