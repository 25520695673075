const scrollToSection = (el, container, event) =>{

	// set vars

	const $el = $(el);

	const $container = $(container);
	let $href;
	let isAnimating = false;
	 
	
	//scroll event
	const fnSetState = (event) => {
		//- scroll to bottom of parent

		if (!isAnimating) {

			let $thisTrigger = $(event.target)
			event.preventDefault();
			$container.addClass('fade-content');

			$href = ($thisTrigger.attr('href') != undefined) ?  $($thisTrigger.attr('href')) : $($thisTrigger.parent().attr('href'));
			
			setTimeout(  () => { fnGoToSection($href) }, 600 );
			
		} else{

			isAnimating = false;
			$container.removeClass('fade-content');
		
		};

	};

	const fnGoToSection = (destination) => {

	
		isAnimating = true;
		
		$('html, body').scrollTop( destination.offset().top );
		
		setTimeout( fnSetState, 700 );

	};

	// event listener
	$el.click( fnSetState );
	
}

export { scrollToSection };

scrollToSection('.pwcc-link', '#page-section-wrap' )