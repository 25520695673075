var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-results-container", attrs: { id: "BranchMap" } },
    [
      _c(
        "gmap-map",
        {
          ref: "physioMap",
          attrs: {
            options: _vm.mapOptions,
            center: _vm.mapCenter(),
            zoom: _vm.mapZoom()
          }
        },
        [
          _vm._l(_vm.branches(), function(branch) {
            return _c("gmap-marker", {
              key: branch.ID,
              attrs: { position: branch.Marker, icon: _vm.setIcon(branch) },
              on: {
                click: function($event) {
                  return _vm.updateSelectedBranch(branch)
                }
              }
            })
          }),
          _vm._v(" "),
          _c(
            "gmap-info-window",
            {
              attrs: {
                options: _vm.infoOptions,
                position: _vm.mapCenter(),
                opened: _vm.infoWindowOpen()
              },
              on: { closeclick: _vm.updateSelectedBranch }
            },
            [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.infoWindowContent()) }
              })
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }