import Vue from 'Node/vue';
import Vuex from 'Node/vuex';
import * as VueGoogleMaps from "Node/vue2-google-maps";
Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBFGcMWPvMWgN9whvDsRgOyIYvPw20mr38", 
        libraries: "places,geometry"
    }
});

export default class VueLoader {
    constructor(components) {
        this.components = components;
    }

    load() {
        let components = this.components;
        for (let selector in components) {
            let objects = document.querySelectorAll(selector),
                appFile = components[selector].app,
                storeFile = components[selector].store;

            let store = new Vuex.Store({
                modules: {storeFile}
            });

            if (objects) {
                Array.from(objects).forEach(() => {
                    new Vue({
                        store,
                        render: r => r(appFile)
                    }).$mount(selector);
                });
            }
        }
    }
}