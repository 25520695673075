// import $ from 'jquery'

// requires _schedule.scss
// requires dragScroll.js

// function that sets the height of the time table based on all the times of the event (getting the latest data-segment attribute)
// also places events / speakers on the time line based on their 24 hour time (data-time)

(function() {

    const $schedule = document.getElementById("schedule");

    document.addEventListener("DOMContentLoaded", function() {

        const scheduleEvent = (function() {

            // set vairables
            const $segmentHeight = 90,
                $eventWidth = 180,
                $startHour = 8,
                $dayLength = $('#times .line').last().attr('data-segment') * $segmentHeight,
                $allRooms = $('.room').toArray(),
                $allRoomNames = $('.room-name').toArray(),
                $allFilters = Array.from(document.querySelectorAll('.room-filters .filter')),
                $rooms = Array.from(document.querySelectorAll('#schedule .room')),
                $allEvents = Array.from(document.querySelectorAll('#schedule .event'));



            const init = () => {
                bindEvents();
                setEventPlacements();
                setRoomPlacements();


            };

            const bindEvents = () => {

                for (let filter in $allFilters) {
                    const thisFilter = $allFilters[filter];
                    thisFilter.addEventListener("click", filterDays);
                }

            };
            const setEventPlacements = () => {

                // set positions of events
                for (let session in $allEvents) {
                    // set variables from the Dom
                    const $thisEvent = $allEvents[session],
                        $startTime = $thisEvent.getAttribute('data-time'),
                        $duration = $thisEvent.getAttribute('data-duration');

                    // calculate based on this element
                    // get the starting hour of the event from the data-time attribute
                    let getHour = Math.floor($startTime / 100),
                        // get the starting minutes of the event from the data-time attribute
                        getMinutes = Math.floor($startTime / 10 % 10),
                        // set the  scale of the position to be inline with the scale of the schedule grid (90px)
                        setScale = ((getHour - $startHour) * 90) + ((getMinutes * 1.5) * 10),
                        eventPosition = setScale - (setScale % 90);

                    // set the position of the event
                    $thisEvent.style.top = setScale.toString() + 'px';
                    // set height based on the duration of the event
                    $thisEvent.style.height = ($duration * 1.5) + "px";

                    // check to see if the event is short
                    if ($duration <= 10) {
                        $thisEvent.classList.add("time-Vshort");
                    }
                    if ($duration < 30) {
                        $thisEvent.classList.add("time-short");
                    }
                    // check to see if the event is medium
                    if ($duration < 70 && $duration > 29) {
                        $thisEvent.classList.add("time-med");
                    }
                }
                /// set the height of the timetable based on the time segments of the schedule.
            };
            const setXAxis = (elem, dataAttr, offset) => {

                // for each of the elements in the set
                for (let $each in elem) {
                    // get the x position as attr x offset - offset
                    let xPos = $(elem[$each]).attr(dataAttr) * offset - offset;
                    // set the position as left
                    $(elem[$each]).css("left", xPos + "px");

                }
            };
            const setRoomPlacements = () => {

                // set the Xaxis of the rooms and room titles.
                setXAxis($allRooms, 'data-room', $eventWidth);
                setXAxis($allRoomNames, 'data-room', $eventWidth);
                // set the height of timetable and time
                $('.timetable.active').css('height', $dayLength + 'px');
                $('.times').css('height', $dayLength + 'px');

            };
            const filterDays = function(event) {
                event.preventDefault();

                let activeTimetable = document.querySelector('.timetable.active');
                //
                activeTimetable.style.height = '0px';
                activeTimetable.classList.remove("active");
                //
                let activeDay = $(".timetable[data-day='" + this.getAttribute('data-day') + "']");
                activeDay.addClass("active");
                $('.timetable.active').css('height', $dayLength + 'px');

            };
            const breakFix = (function(el) {
                let $labels = $("#room-titles"),
                    $times = $("#times"),
                    $schedule = $("#schedule");

                $times.css("top", "-" + el.scrollTop + "px");
                $labels.css("left", "-" + el.scrollLeft + "px");
                // stop breaks from moving
                $(".break").css("left", el.scrollLeft + "px");

            });
            return {
                init: init,
                breakFix: breakFix
            };
        })();





        if ($("#schedule").length) {
            scheduleEvent.init();
            $schedule.addEventListener("scroll", function() { scheduleEvent.breakFix($schedule); }, false);
            window.addEventListener("scroll", function() {
                if ($(window).width > 767) {

                    if (($schedule.getBoundingClientRect().top) - 115 < 0) {
                        $('#room-titles').css("top", ($schedule.getBoundingClientRect().top * -1) + 115 + "px");
                    } else {
                        $('#room-titles').css("top", "0px");
                    }
                }
                if (window.innerWidth < 768) {
                    if (($schedule.getBoundingClientRect().top) - 85 < 0) {
                        $('#room-titles').css("top", ($schedule.getBoundingClientRect().top * -1) + 85 + "px");
                    } else {
                        $('#room-titles').css("top", "0px");
                    }
                }
            }, false);
        }
    });
})()