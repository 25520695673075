<template>
    <div id="BranchList" class="search-results-container">
        <h4>PNZ physios</h4>
        <div class="search-results-list">
            <div class="search-results-content">
                <div class="panel-group reveal" role="tablist" aria-multiselectable="true">
                    <div v-for="branch in branches()" :key="branch.ID" :id="branch.ID" class="panel panel-default">
                        <div class="panel-heading" role="tab">
                            <h4 class="panel-title">
                                <a :class="isSelected(branch) ? '' : 'collapsed'" role="button"
                                   @click="updateSelectedBranch(branch)"><span v-html="branch.Name"></span></a>
                            </h4>
                        </div>
                        <div class="panel-collapse collapse" :class="isSelected(branch) ? 'in' : ''" role="tabpanel">
                            <div class="panel-body">
                                <p>{{locationData(branch).AddressPrefix}}</p>
                                <p>{{locationData(branch).StreetAddress}}</p>
                                <p>{{locationData(branch).Region}}</p>
                                <p>{{locationData(branch).City}} {{locationData(branch).PostCode}}</p>
                                <a class="more" :href="'/CompanyProfile?Action=View&CompanyProfile_id=' + locationData(branch).UrlSegment">View
                                    profile</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'Node/vuex';

    export default {
        name: 'BranchList',
        props: {
            branches: {type: Function}
        },
        methods: {
            ...mapActions(['updateSelectedBranch']),
            isSelected(branch) {
                return branch === this.selectedBranch;
            },
            locationData(branch) {
                let locations = this.loadedLocations,
                    match = locations.filter((find) => {
                        return find.ID === branch.ID;
                    });

                if (match.length > 0) {
                    return match[0];
                }
                return [];
            }
        },
        computed: {
            ...mapGetters(['allBranches', 'filteredBranches', 'selectedBranch', 'loadedLocations']),
            branchCount() {
                return this.branches().length;
            }
        }
    };
</script>

<style scoped lang="scss">
    .panel-body {
        p {
            margin: 0;
        }
    }
</style>