

const multiStepFilter = () =>{
    
    //------- set Vars
    // Regions
    let regions = {};

    let step = "region";
    let region, district, suburb, query, regiondff;

    const componentContainer = $("#multi-step-filter"),
    regionsContainer = componentContainer.find('.regions'),
    districtsContainer = componentContainer.find('.districts'),
    districtsList = districtsContainer.find('[data-step]'),
    suburbsContainer = componentContainer.find('.suburbs'),
    suburbsList = suburbsContainer.find('[data-step]'),
    physioResults = $("#physio-results"),
    physioList = $("section.physio-list");

    const getRegionData = () =>{
        if(physioResults.length)
        {
            physioList.slideUp();
            $.ajax({
                dataType: "json",
                url: "/Company?Action=Get%20Regions%20and%20Districts",
                success: function (result) { 
                    bindEvents();
                    if(result.success)
                    {
                        regions = result;
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) { 
                    alert("Failed to retrieve regions and districts. Error Received: " + errorThrown + " - " + textStatus);
                }
            });
        }
        else
        {
            bindEvents();
        }
    }

    const createPhysioBlock = (data) => {

        var address = "",
            phone = "",
            more = "";

        if(typeof data.Address_line1 !== 'undefined' && data.Address_line1 != "" && typeof data.Address_city !== 'undefined' && data.Address_city != "")
        {
            address = $('<li/>', { class: 'address'}).append(data.Address_line1 + ", " + data.Address_city);
        }

        if(typeof data.Location_telbus !== 'undefined' && data.Location_telbus != "")
        {
            phone = $('<li/>', { class: 'phone'}).append($('<a/>', { href: "tel:" + data.Location_telbus}).append("Call " + data.Location_telbus));
        }

        more = $('<li/>', { class: 'more'}).append($('<a/>', { href: data.CompanyProfile_urlview.replace('&amp;','&')}).append("More details"));

        const container = $('<div/>', {class: 'col-md-4', style: 'display: none;'})
            .append(
                $('<div/>', {class: 'card physio-card'})
                .append($('<div/>', {class: 'card-title'}).append($('<a/>', {href: data.CompanyProfile_urlview.replace('&amp;','&')}).append('<h4>' + data.CompanyProfile_title + '</h4>')))
                .append($('<div/>', {class: 'card-block'}).append($('<ul/>').append(address).append(phone).append(more)))
            );
        physioResults.append(container);
    }

    const items_found_template = $('<div/>', {class: 'col-md-12 physio-results', id: 'results-found'}).append("<h2>PNZ physios found</h2>");
    const no_items_found_template = $('<div/>', {class: 'col-md-12 physio-results top60 bottom60', id: 'no-results-found'}).append("<h2>No results found.</h2>");
    const load_more_items_template = $('<div/>', {class: 'col-md-12 top60 bottom60'}).append("<center><button class='btn btn-primary' id='load-more-results'>Load more results</button></center>");

    var currentPage = 0;
    var dataFilter = {};

    const searchPhysios = (e) => {
        if(typeof regiondff !== 'undefined' && regiondff != "")
            dataFilter["DFF_2196"] = regiondff;

        if(typeof district !== 'undefined' && district != "")
            dataFilter["DFF_2197"] = district;
        
        $.ajax({
            dataType: "json",
            data: dataFilter,
            url: "https://physio.org.nz/DataFilter?Action=AjaxList&DataFilter_id=322&Page=" + currentPage,
            success: function (result) { 
                currentPage++;
                if(result.TotalItems == 0)
                {
                    if(!$("#no-results-found").length)
                    {
                        physioResults.append(no_items_found_template);
                        physioList.slideDown();
                    }
                }
                else
                {
                    if(!$("#results-found").length)
                    {
                        physioResults.append(items_found_template);
                    }

                    $("#load-more-results").unbind();
                    if(result.Items.length == 0)
                    {
                        setTimeout(function(){
                            if($("#load-more-results").length)
                            {
                                $("#load-more-results").html("No more results");
                            }
                        }, 1500);
                    }
                    else
                    {
                        var old_height = $(document).height();  //store document height before modifications
                        var old_scroll = $(window).scrollTop(); //remember the scroll position

                        $.each(result.Items, function(idx, ele){
                            createPhysioBlock(ele);
                        });
                        
                        physioList.slideDown(function(){
                            $.each($(".col-md-4:hidden"), function(idx, ele){
                                $(".col-md-4:hidden").slideDown();
                            });
                        });

                        $(".physio-card").matchHeight();

                        $(document).scrollTop(old_scroll + $(document).height() - old_height);

                        loadMoreResults();

                        setTimeout(function(){
                            if($("#load-more-results").length)
                            {
                                $("#load-more-results").html("Load more results");
                            }
                        }, 850);
                    }
                    
                }

                
            },
            error: function (jqXHR, textStatus, errorThrown) { 
                alert("Failed to retrieve regions and districts. Error Received: " + errorThrown + " - " + textStatus);
            }
        });
    }

    const filterReset = (callback) => {
        physioResults.empty();
        currentPage = 0;
        dataFilter = {};

        if (callback && typeof callback === "function") {
            callback();
        }

      //  searchPhysios();
    }

    const filterResults = () => {
        filterReset();
    }

    const filterByArea = (e) => {
        event.preventDefault();
        var area = $(e.target).text();
        filterReset(function(){
            dataFilter["DFF_2195"] = area;
        });
    }

    const filterByCondition = (e) => {
        event.preventDefault();
        var condition = $(e.target).text();
        filterReset(function(){
            dataFilter["DFF_2194"] = condition;
        });
    }

    const loadMoreResults = () =>{
        physioResults.append(load_more_items_template);
        $("#load-more-results").click(function(event){
            event.preventDefault();
            $("#load-more-results").append("&nbsp;<i class='fa fa-spinner fa-spin' id='load-more-results-spinner'></i>");
            searchPhysios();
        });
    }

    const keywordSearch = (e) => {
        event.preventDefault();
        filterReset(function(){
            dataFilter["DataFilter_Find"] = $("#keyword-search-form input").val();
        });
    }

    //------- Bind Events / Set eventlisteners
    const bindEvents = () =>{
        $('#keyword-search-form').submit(keywordSearch);
        $('#keyword-search-accordian').click(closePhysioListIfVisible);
        $('.search-options-container button').click(closeOptions);
        $('[data-region]').click(setRegion);
        $('.filter-steps .region').click(backToRegion);
        $('.filter-steps .district').click(backToDistrict);

        $('#areasOfPractice a').click(filterByArea);
        $('#conditions a').click(filterByCondition);
        $('#find_a_physio').click(filterResults);
        
        // $('.filter-steps .suburb').click(backToSuburb)
    }

    //----- Functions
    //------------

    const closePhysioListIfVisible = () => {
        if($("section.physio-list:visible"))
            physioList.slideUp();
    }

    const closeOptions = () =>{

        $('.filter-options').slideUp();
        $('#multi-step-filter').addClass('closed');
    }

    const setRegion = (e) =>{

        region = $(e.target).text();
        regiondff = $(e.target).attr("data-region");
        // region = region.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')

        for (let district in regions[region] ){
            districtsList.append("<li data-district='"+ regions[region][district].value +"' >" + regions[region][district].name + " </li>");
        }   

        regionsContainer.removeClass('active').addClass('inactive');

        $('.filter-steps .region').removeClass('active').find('span').html("<p class='set'>" + region +" </p>");
        $('.filter-steps .district').addClass('active').removeClass('disabled');

        setTimeout( () =>{ 
            regionsContainer.removeClass('inactive');
            districtsContainer.addClass('active'); 

        } , 400);

        // regionsContainer.removeClass('active');

        
        $('[data-district]').click(setDistrict);
        

    }


    //-------------


    const setDistrict = (e) =>{

        district = $(e.target).attr("data-district");

        let thisRegion = regions[region];

        for (let suburb in thisRegion[district] ){
            suburbsList.append("<li data-suburb='"+ thisRegion[district][suburb].value +"'>"+ thisRegion[district][suburb].name+ " </li>");
        } 

        districtsContainer.removeClass('active').addClass('inactive');

        $('.filter-steps .district').removeClass('active').find('span').html("<p class='set'>" + district +" </p>");
        $('.filter-steps .suburb').addClass('active').removeClass('disabled');

        setTimeout( () =>{ 
            districtsContainer.removeClass('inactive');
            suburbsContainer.addClass('active'); 

        } , 400);

        $('.filter-steps .suburb').find('span').html("<p class='set'>" + suburb +" </p>");

        $('#multi-step-filter').addClass('closed');
        $('.filter-options').slideUp();

        filterResults();
    }


    //---------


    // const setSuburb = (e) => {

    //  suburb = $(e.target).attr("data-suburb");

    //  districtsContainer.removeClass('active');
    //  setTimeout( () =>{ 
    //      districtsContainer.removeClass('inactive');
    //      suburbsContainer.addClass('active'); 

    //  }, 400);

    

    //  $('.filter-steps .suburb').find('span').html("<p class='set'>" + suburb +" </p>");

    //  $('#multi-step-filter').addClass('closed');
    //  $('.filter-options').slideUp();
    // }

    ///----------

    // const backToSuburb = (e) => {

    //  if ( !$('.filter-steps .subrub').hasClass('disabled') ) {
    //                  $('.filter-options').slideDown();
    //  }
    //  $('#multi-step-filter').removeClass('closed');
    // }


    const backToDistrict = (e) => {

        physioList.slideUp();
        if ( !$('.filter-steps .district').hasClass('disabled') ) {


            $('.filter-steps .district').addClass('active');
            suburbsContainer.removeClass('active').addClass('inactive')

            setTimeout( () =>{ 

                suburbsContainer.removeClass('inactive');
                suburbsList.html('');
                districtsContainer.addClass('active'); 

            } , 400);

            
        }
        $('.filter-options').slideDown();
        $('#multi-step-filter').removeClass('closed');
    }

    const backToRegion = (e) => {
        var delay;

        district = "";

        physioList.slideUp();

        $('.filter-steps .district').addClass('disabled').removeClass('active').find('span').html('');

        $('.filter-steps .region').addClass('active');


        if( districtsContainer.hasClass('active') ){

            districtsContainer.removeClass('active').addClass('inactive');

            delay = 400;

        }
        setTimeout( () =>{ 

            districtsContainer.removeClass('inactive');
            regionsContainer.addClass('active'); 

                // suburbsList.html('');
                districtsList.html('');
            } , delay);
        $('.filter-options').slideDown();
        $('#multi-step-filter').removeClass('closed');
    }

    const activeTag = (e) =>{
        e.preventDefault();
        if( !$(e.target).parent().hasClass('active') ){
            $(e.target).parent().siblings().removeClass('active');
            $(e.target).parent().addClass('active');
        }
        else if ( $(e.target).parent().hasClass('active').not(':first-of-type') ){
            $(e.target).parent().removeClass('active');
        }
    }

    $("#all-filter").click(filterReset);
    $('#tags-filter .tag-options li a').click(activeTag);
    // getRegionData();

};



export { multiStepFilter };



