
const countdown = (time) => {

	let countDownDate = new Date(time).getTime();

	// Update the count down every 1 second
	let x = setInterval(function() {

	  // Get todays date and time
	  let now = new Date().getTime();

	  // Find the distance between now and the count down date
	  let distance = countDownDate - now;

	  // Time calculations for days, hours, minutes and seconds
	  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
	  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	  let seconds = Math.floor((distance % (1000 * 60)) / 1000);

	  const wrapTimes = (el) =>{

	  };

	  let daysHtml = "<div>"+ days +"<span>Days</span></div>";
	  let hoursHtml = "<div>"+ hours +"<span>Hrs</span></div>";
	  let minutesHtml = "<div>"+ minutes +"<span>Mins</span></div>"
	  let secondsHtml = "<div>"+ seconds +"<span>Secs</span></div>"




	  // Display the result in the element with id="demo"
	  $(".countdown").html(daysHtml + hoursHtml + minutesHtml + secondsHtml);
	 $(".countdown").addClass('visible')
	  // If the count down is finished, write some text 
	  if (distance < 0) {
	  	clearInterval(x);

	  	$(".countdown").html("On Now!");

	  }
	}, 1000);

};

export { countdown }