  ///////////////////////
  // Map
  /////////////////////
  var regions = $('.map-selector').find('li'),
  totalRegions = regions.length,
  mapRegions = $("#map").find(".region");

  for ( let r = 0; r < totalRegions; r++ ) {

    $(regions[r]).bind( 'mouseenter mouseleave', function(){
     var regionId = $( "#" + $(this).attr('data-map'));

     $( regionId).toggleClass("hover");

   });
    $(mapRegions[r]).bind('mouseenter mouseleave', function(){
      var regionId =   $(this).attr('id');
      console.log(regionId );
      $('[data-map="' + regionId + '"]').toggleClass("hover");
    });
}