var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-results-container", attrs: { id: "BranchList" } },
    [
      _c("h4", [_vm._v("PNZ physios")]),
      _vm._v(" "),
      _c("div", { staticClass: "search-results-list" }, [
        _c("div", { staticClass: "search-results-content" }, [
          _c(
            "div",
            {
              staticClass: "panel-group reveal",
              attrs: { role: "tablist", "aria-multiselectable": "true" }
            },
            _vm._l(_vm.branches(), function(branch) {
              return _c(
                "div",
                {
                  key: branch.ID,
                  staticClass: "panel panel-default",
                  attrs: { id: branch.ID }
                },
                [
                  _c(
                    "div",
                    { staticClass: "panel-heading", attrs: { role: "tab" } },
                    [
                      _c("h4", { staticClass: "panel-title" }, [
                        _c(
                          "a",
                          {
                            class: _vm.isSelected(branch) ? "" : "collapsed",
                            attrs: { role: "button" },
                            on: {
                              click: function($event) {
                                return _vm.updateSelectedBranch(branch)
                              }
                            }
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(branch.Name) }
                            })
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "panel-collapse collapse",
                      class: _vm.isSelected(branch) ? "in" : "",
                      attrs: { role: "tabpanel" }
                    },
                    [
                      _c("div", { staticClass: "panel-body" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.locationData(branch).AddressPrefix))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(_vm._s(_vm.locationData(branch).StreetAddress))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(_vm._s(_vm.locationData(branch).Region))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.locationData(branch).City) +
                              " " +
                              _vm._s(_vm.locationData(branch).PostCode)
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "more",
                            attrs: {
                              href:
                                "/CompanyProfile?Action=View&CompanyProfile_id=" +
                                _vm.locationData(branch).UrlSegment
                            }
                          },
                          [
                            _vm._v(
                              "View\n                                profile"
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }