<template>
    <div id="BranchMap" class="search-results-container">
        <gmap-map ref="physioMap" :options="mapOptions" :center="mapCenter()" :zoom="mapZoom()">
            <gmap-marker v-for="branch in branches()" :key="branch.ID" :position="branch.Marker" @click="updateSelectedBranch(branch)" :icon="setIcon(branch)"></gmap-marker>
            <gmap-info-window :options="infoOptions" :position="mapCenter()" :opened="infoWindowOpen()" @closeclick="updateSelectedBranch">
                <div v-html="infoWindowContent()"></div>
            </gmap-info-window>
        </gmap-map>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'Node/vuex';

    export default {
        name: 'BranchMap',
        data() {
            return {
                mapOptions: {
                    mapTypeControl: false,
                    streetViewControl: false
                },
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -30
                    }
                }
            };
        },
        props: {
            branches: {type: Function}
        },
        methods: {
            ...mapActions(['fetchBranches', 'updateSelectedBranch']),
            infoWindowContent() {
                let branch = this.selectedBranch;
                if (branch) {
                    return (`
                        <div class="card">
                            <div class="card-content">
                                <p>${branch.Name}</p>
                                <a target="_blank" href="http://maps.google.com?daddr=${branch.DisplayAddress}">
                                    <i class="fal fa-map-marker-alt"></i>
                                    <span class="address-detail">Get directions</span>
                                </a>
                            </div>
                        </div>
                    `);
                };
            },
            infoWindowOpen() {
                let branch = this.selectedBranch;
                return branch ? true : false;
            },
            mapCenter() {
                try {
                    let branch = this.selectedBranch;
                    if (branch && branch.Marker) {
                        return branch.Marker;
                    } else {
                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(function(position) { return { lat: position.coords.latitude, lng: position.coords.longitude }; }, 
                                function(e) { console.log(e); return { lat: -41.4139997, lng: 171.8003207 }; }, { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 });
                        } else {
                            console.log("Geolocation is not supported.");
                            return { lat: -41.4139997, lng: 171.8003207 };
                        }
                    }
                } catch (err) {
                    console.log(err);
                    return { lat: -41.4139997, lng: 171.8003207 };
                }
            },
            mapZoom() {
                let branch = this.selectedBranch;
                return branch ? 14 : 10;
            },
            setIcon(branch){
                let selected = this.selectedBranch;
                if(selected && branch.ID === selected.ID){
                    return { url: require('../../../../images/map-marker-large.svg')};
                }
                return { url: require('../../../../images/blue-marker-solid.svg')}
            }
        },
        computed: mapGetters(['allBranches', 'filteredBranches', 'selectedBranch','filterBranches']),
        mounted : function() {
            let self = this;
            this.$nextTick(() => {
                self.$refs.physioMap.$mapPromise.then(function(map) {
                    try{
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function(position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            };
                            map.setCenter(pos);
                        },function (e) {}, {enableHighAccuracy: true, timeout: 5000, maximumAge: 0});
                    } else {
                        console.log("Geolocation is not supported.");
                    }
                }catch(err) {console.log(err);}
                });
            });
        }
    };
</script>

<style lang="scss">
    #BranchMap {
        height: 758px;
        padding: 0;

        .vue-map-container {
            height: 100%;
        }

        .gm-style-iw-c {
            background: rgba(100, 100, 100, 0.8);
            border-radius: 0;
            padding: 0 !important;

            .gm-style-iw-d {
                overflow: hidden !important;

                .card {
                    margin: 0;
                    padding: 20px;
                }
            }
        }
    }
</style>