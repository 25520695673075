  ///////////////////////
  // Member accordian
  /////////////////////
if ($(window).width() < 767) {

   $('.physio-member-heading').on('click', function () {
        $('.physio-member-heading').toggleClass( "open");
        $('.physio-members').slideToggle();
    });

}