<template>
  <div id="BranchFilter" class="search-options-container">
    <div
      class="accordian active"
      id="address-search"
      data-toggle-slide="address-search"
    >
      <form>
        <div class="input-group">
          <div class="form-section">
            <div class="input-group">
              <gmap-autocomplete
                :options="autocompleteOptions"
                @place_changed="findClosestBranch"
                @keydown.native.enter.prevent
                placeholder="Enter an address"
                :value="userAddress"
                class="form-control"
              ></gmap-autocomplete>
              <div class="input-group-btn">
                <button @click="geoLocate($event)">
                  <i class="fal fa-location"></i><span>Use my location</span>
                </button>
              </div>
            </div>
          </div>
          <div class="form-section half-col-inputs">
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Type Keyword"
                @change="filterBranches"
                name="keyword"
              />
              <span class="btn-clear" @click="clearInput($event)"
                ><i class="fa fa-times" aria-hidden="true"></i
              ></span>
            </div>
            <div class="form-group">
              <div class="custom-select">
                <input type="hidden" name="help" />
                <span class="btn-clear" @click="clearInput($event)"
                  ><i class="fa fa-times" aria-hidden="true"></i
                ></span>
                <div class="select-selected" @click="toggleSelect($event)">
                  {{ defaultHelpText }}
                </div>
                <div class="select-items">
                  <div @click="selectHelp($event)" data-value="">
                    {{ defaultHelpText }}
                  </div>
                  <div
                    v-for="help in helpList"
                    :key="help"
                    :data-value="help"
                    @click="selectHelp($event)"
                  >
                    {{ help }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-btn" style="display: flex; justify-content: center;">
          <button
            class="btn btn-dark-solid"
            @click="filterBranches"
            style="margin-right: 5px"
          >
            Find Physios
          </button>
          <!-- <a class="btn btn-dark-solid customized" :href="url">
            Book on WellNow
          </a> -->
          <!-- <div class="external-links" style="padding-top: 0px;"><a href="https://pnz.org.nz/Story?Action=View&amp;Story_id=5127" style="margin-top: 40px;"><span class="see-more" style="margin-top: 45px; margin-left: 5px;">About WellNow</span></a></div> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'Node/vuex';

export default {
  name: 'BranchFilter',
  data() {
    return {
      autocompleteOptions: {
        componentRestrictions: {
          country: ['nz'],
        },
      },
      defaultHelpText: 'I need help with',
      url: 'https://wellnow.nz/booking?specialty=physiotherapy&refer=physionz',
    };
  },
  props: {
    branches: { type: Function },
  },
  methods: {
    ...mapActions([
      'updateSelectedBranch',
      'updateBranches',
      'updateFilteredBranches',
      'filterBranches',
      'updateUserAddress',
    ]),
    geoLocate: function (e) {
      try {
        if (e) e.preventDefault();
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            this.showPosition,
            function (e) {},
            { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
          );
        } else {
          console.log('Geolocation is not supported.');
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    showPosition: function (position) {
      this.sortBranches(
        new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        )
      );

      if (
        typeof this.branches[0] !== 'undefined' &&
        this.branches[0] !== this.selectedBranch
      ) {
        this.updateSelectedBranch(this.branches[0]);
      }

      let geocoder = new google.maps.Geocoder(),
        location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

      geocoder.geocode({ location: location }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (typeof results[0] !== 'undefined') {
            this.updateUserAddress(results[0].formatted_address);
            //console.log('result', results[0]);
            this.url = `https://wellnow.nz/booking?specialty=physiotherapy&position=${results[0].geometry.location.lat()},${results[0].geometry.location.lng()}&refer=physionz`;
          }
        }
      });
    },
    sortBranches(userLocation) {
      let sortedBranches = this.allBranches.sort((a, b) => {
        let aLocation = new google.maps.LatLng(a.Marker.lat, a.Marker.lng),
          bLocation = new google.maps.LatLng(b.Marker.lat, b.Marker.lng),
          aDist = google.maps.geometry.spherical.computeDistanceBetween(
            userLocation,
            aLocation
          ),
          bDist = google.maps.geometry.spherical.computeDistanceBetween(
            userLocation,
            bLocation
          );
        //console.log('aLocation1', aLocation, 'bLocation2', bLocation);
        return aDist - bDist;
      });

      let sortedFilteredBranches = this.filteredBranches.sort((a, b) => {
        let aLocation = new google.maps.LatLng(a.Marker.lat, a.Marker.lng),
          bLocation = new google.maps.LatLng(b.Marker.lat, b.Marker.lng),
          aDist = google.maps.geometry.spherical.computeDistanceBetween(
            userLocation,
            aLocation
          ),
          bDist = google.maps.geometry.spherical.computeDistanceBetween(
            userLocation,
            bLocation
          );
        //console.log('aLocation3', aLocation, 'bLocation4', bLocation);
        return aDist - bDist;
      });

      this.updateBranches(sortedBranches);
      this.updateFilteredBranches(sortedFilteredBranches);
    },

    findClosestBranch: function (place) {
      if (place) {
        //console.log('place', place);
        //console.log('url1', this.url);
        this.url = `https://wellnow.nz/booking?specialty=physiotherapy&position=${place.geometry.location.lat()},${place.geometry.location.lng()}&refer=physionz`;
        //console.log('url2', this.url);
        this.sortBranches(place.geometry.location);
        if (
          typeof this.branches[0] !== 'undefined' &&
          this.branches[0] !== this.selectedBranch
        ) {
          //console.log('branches1', this.branches[0]);
          this.updateSelectedBranch(this.branches[0]);
        }
        this.updateUserAddress(place.formatted_address);
      }
    },
    toggleSelect(e) {
      let container = e.target.closest('.custom-select'),
        options = container.querySelector('.select-items');
      options.classList.toggle('show');
    },
    selectHelp(e) {
      let container = e.target.closest('.custom-select'),
        options = container.querySelector('.select-items'),
        display = container.querySelector('.select-selected'),
        input = container.querySelector('input[name="help"]'),
        value = e.target.dataset.value;
      display.innerText = value;
      input.value = value;
      if (value === '') {
        display.innerText = this.$data.defaultHelpText;
      }
      options.classList.toggle('show');

      this.filterBranches(e);
    },
    clearInput(e) {
      let container = e.target.closest('.form-group'),
        input = container.querySelector('input'),
        display = container.querySelector('.select-selected');
      input.value = '';
      if (display) {
        display.innerText = this.$data.defaultHelpText;
      }

      this.filterBranches(e);
    },
  },
  computed: {
    ...mapGetters([
      'allBranches',
      'filteredBranches',
      'selectedBranch',
      'userAddress',
      'helpList',
    ]),
  },
  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      this.geoLocate();
    });
  },
};
</script>

<style scoped lang="scss">
.select-items {
  max-height: 0;
  transition: max-height 0.2s ease-in-out;
  overflow: hidden;

  div {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &.show {
    max-height: 275px;

    div {
      opacity: 1;
    }
  }
}

.btn-clear {
  cursor: pointer;
  position: absolute;
  right: 36px;
  color: #00bce7;
  z-index: 2;
  line-height: 60px;
}
</style>
