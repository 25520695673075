const shrinkNav = (() =>{

  $(document).on('scroll', () => {

    if($(document).scrollTop() > 100){
        $(".header-wrap").addClass("shrink");
      } else {
        $(".header-wrap").removeClass("shrink");
      }

  });

})();

export { shrinkNav };