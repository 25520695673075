var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-options-container", attrs: { id: "BranchFilter" } },
    [
      _c(
        "div",
        {
          staticClass: "accordian active",
          attrs: { id: "address-search", "data-toggle-slide": "address-search" }
        },
        [
          _c("form", [
            _c("div", { staticClass: "input-group" }, [
              _c("div", { staticClass: "form-section" }, [
                _c(
                  "div",
                  { staticClass: "input-group" },
                  [
                    _c("gmap-autocomplete", {
                      staticClass: "form-control",
                      attrs: {
                        options: _vm.autocompleteOptions,
                        placeholder: "Enter an address",
                        value: _vm.userAddress
                      },
                      on: { place_changed: _vm.findClosestBranch },
                      nativeOn: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.preventDefault()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-btn" }, [
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.geoLocate($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fal fa-location" }),
                          _c("span", [_vm._v("Use my location")])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-section half-col-inputs" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: { placeholder: "Type Keyword", name: "keyword" },
                    on: { change: _vm.filterBranches }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "btn-clear",
                      on: {
                        click: function($event) {
                          return _vm.clearInput($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "custom-select" }, [
                    _c("input", { attrs: { type: "hidden", name: "help" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "btn-clear",
                        on: {
                          click: function($event) {
                            return _vm.clearInput($event)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-times",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "select-selected",
                        on: {
                          click: function($event) {
                            return _vm.toggleSelect($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.defaultHelpText) +
                            "\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "select-items" },
                      [
                        _c(
                          "div",
                          {
                            attrs: { "data-value": "" },
                            on: {
                              click: function($event) {
                                return _vm.selectHelp($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.defaultHelpText) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.helpList, function(help) {
                          return _c(
                            "div",
                            {
                              key: help,
                              attrs: { "data-value": help },
                              on: {
                                click: function($event) {
                                  return _vm.selectHelp($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(help) +
                                  "\n                "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "filter-btn",
                staticStyle: { display: "flex", "justify-content": "center" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-dark-solid",
                    staticStyle: { "margin-right": "5px" },
                    on: { click: _vm.filterBranches }
                  },
                  [_vm._v("\n          Find Physios\n        ")]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }