const pwccNav =  (function (){
	
	const $trigger = document.querySelector('.nav-toggler') ;

	

	const toggleOpen = function () {

		document.querySelector('.side-col').classList.toggle('open') ;
		document.querySelector('body').classList.toggle('stop-scroll') ;
		console.log('test');

	};
	if($trigger){
		$trigger.addEventListener("click", toggleOpen);
	}

})();

export { pwccNav };