import { render, staticRenderFns } from "./BranchFilter.vue?vue&type=template&id=676b2354&scoped=true&"
import script from "./BranchFilter.vue?vue&type=script&lang=js&"
export * from "./BranchFilter.vue?vue&type=script&lang=js&"
import style0 from "./BranchFilter.vue?vue&type=style&index=0&id=676b2354&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../build_scripts/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676b2354",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/yuenyuan/physio-rebuild/build_scripts/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('676b2354')) {
      api.createRecord('676b2354', component.options)
    } else {
      api.reload('676b2354', component.options)
    }
    module.hot.accept("./BranchFilter.vue?vue&type=template&id=676b2354&scoped=true&", function () {
      api.rerender('676b2354', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/vue/components/locator/BranchFilter.vue"
export default component.exports