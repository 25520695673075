const detectPageSection = (el, parent, nav) => {

 let $pageSections = Array.prototype.slice.apply( document.querySelectorAll(el) );
 let $wrap         = document.querySelector(parent);
 let $nav           = document.querySelector(nav) ? Array.prototype.slice.apply( document.querySelector(nav).getElementsByTagName('a')) : '';
 let navArray      = '';

  // vanilla js
  if(document.querySelector(nav)){
    $pageSections = Array.prototype.slice.apply( document.querySelectorAll(el) );
    $wrap         = document.querySelector(parent);
    $nav           = nav ? Array.prototype.slice.apply( document.querySelector(nav).getElementsByTagName('a')) : '';
    navArray      = '';

  }


  const fnMarkActive = function(el){
    let activeSearch = el;

    $nav.find( function(element){

      if( element.getAttribute('href') == '#' + activeSearch ){
        console.log(element.getAttribute('href'))
        element.parentElement.classList.add('active');
      } else{
        element.parentElement.classList.remove('active');
      };

    }) 

  };


  const fnDetectPosition = (el) => {

    let bounding = el.getBoundingClientRect();
    let viewHeight = (window.innerHeight || document.documentElement.clientHeight);

    if ( bounding.top <= viewHeight - 100 && bounding.bottom >= (window.innerHeight || document.documentElement.clientHeight) ) {

      // Top is 100px in view 
      let sectionClass = el.getAttribute('data-page-section');
      let currentSection = $wrap.getAttribute('data-active-section');

      if ( currentSection != sectionClass ){
        $wrap.setAttribute('data-active-section', sectionClass );
        fnMarkActive(sectionClass);
      }

    }

  };

  // bind events
  if ($pageSections){
   window.onscroll = () => {
    $pageSections.forEach( (element) => {

      fnDetectPosition(element);

    })
  };
}


};

export { detectPageSection };