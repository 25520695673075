const slideRevealer = ( () =>{

	let $target = $('a[data-toggle-slide]');

	const toggleSlide = (e) =>{

		e.preventDefault();
		let $parent = $(e.target).attr('data-parent');
		let $slideName = $(e.target).attr('data-toggle-slide');
		let $partner = $('.accordian[data-toggle-slide="' + $slideName + '"]')

		if( !$(e.target).hasClass('active') ){
			$(e.target).addClass('active').siblings().removeClass('active');
		}
		

		if( !$partner.hasClass('active') ){
			$partner.slideDown();
			$partner.siblings('').slideUp();
			setTimeout( () =>{
				$partner.addClass('active');
				$partner.siblings('').removeClass('active');
			}, 300)
		}
	}


	$target.on('click', toggleSlide);




})();


export { slideRevealer };