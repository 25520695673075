var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "BranchLocator" } }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [_c("BranchFilter", { attrs: { branches: _vm.branches } })],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: _vm.isLoaded ? "" : "loading" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "div",
          { staticClass: "col-md-5" },
          [_c("BranchList", { attrs: { branches: _vm.branches } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-7" },
          [_c("BranchMap", { attrs: { branches: _vm.branches } })],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }