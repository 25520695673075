///////////////////////
//password reveal
/////////////////////

const passwordReveal = (() =>{


	const togglePassword = () => {

		let $x = $("#password");

		if ($x.attr("type") == "password") {
			$x.attr("type", "text");
		} 
		else {
			$x.attr("type", "password");
		};

		$(this).toggleClass('fa-eye-slash').toggleClass('fa-eye');

	}

	$(".view-toggle").on("click", togglePassword );

})();

export { passwordReveal };