<template>
    <section id="BranchLocator">
        <div class="row">
            <div class="col-sm-12">
                <BranchFilter :branches="branches"/>
            </div>
        </div>
        <div class="row" :class="isLoaded ? '' : 'loading'">
            <div class="col-sm-12">
                <div class="col-md-5">
                    <BranchList :branches="branches"/>
                </div>
                <div class="col-md-7">
                    <BranchMap :branches="branches"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import BranchFilter from './BranchFilter';
    import BranchList from './BranchList';
    import BranchMap from './BranchMap';
    import {mapGetters, mapActions} from 'Node/vuex';

    export default {
        name: 'BranchLocator',
        components: {
            BranchFilter,
            BranchList,
            BranchMap
        },
        methods: {
            ...mapActions(['fetchBranches']),
            branches() {
                if (this.allowEmptyResults || this.filteredBranches.length) {
                    return this.filteredBranches;
                }
                return this.allBranches;
            }
        },
        computed: {
            ...mapGetters(['isLoaded', 'allBranches', 'filteredBranches', 'allowEmptyResults'])
        },
        created() {
            this.$gmapApiPromiseLazy().then(() => {
                this.fetchBranches();
            });
        }
    };
</script>

<style scoped lang="scss">
    .col-sm-12 > div {
        padding: 0;
    }

    @media(max-width: 992px) {
        .col-sm-12 {
            display: flex;
            flex-direction: column-reverse;

            #BranchMap {
                height: 300px;
            }
        }
    }
</style>