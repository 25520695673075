import axios from 'Node/axios';

const state = {
    branches: [],
    locations: [],
    filteredBranches: [],
    loaded: false,
    selectedBranch: null,
    selectedLocation: null,
    userAddress: '',
    helpList: [],
    allowEmptyResults: false
};

const getters = {
    allBranches: () => state.branches,
    filteredBranches: () => state.filteredBranches,
    loadedLocations: () => state.locations,
    selectedBranch: () => state.selectedBranch,
    isLoaded: () => state.loaded,
    userAddress: () => state.userAddress,
    helpList: () => state.helpList,
    allowEmptyResults: () => state.allowEmptyResults
};

const actions = {
    async fetchBranches({dispatch, commit}) {
        const response = await axios.get('/Company?Action=List Physios');
        if (Array.isArray(response.data.Branches)) {
            commit('setBranches', response.data.Branches);

            let helpList = [];
            response.data.Branches.map((branch) => {
                helpList.push(...branch.Tags);
            });

            commit('setHelpList', [...new Set(helpList)]);
            commit('setLoaded', true);
        }
    },
    decodeAddress({commit}, branch) {
        const street = branch.StreetAddress,
            region = branch.Region,
            city = branch.City,
            postcode = branch.PostCode,
            searchAddress = street + ' ' + region + ' ' + city + ' ' + postcode,
            geocoder = new google.maps.Geocoder();
        return new Promise((resolve, reject) => {
            geocoder.geocode({'address': searchAddress}, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    let latitude = results[0].geometry.location.lat(),
                        longitude = results[0].geometry.location.lng();
                    branch.Marker = {
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude),
                    };
                    branch.DisplayAddress = searchAddress;
                    resolve(branch);
                } else {
                    reject(branch);
                }
            })
        });
    },
    updateBranches({commit}, branches) {
        commit('setBranches', branches);
    },
    updateFilteredBranches({commit}, branches) {
        commit('setFilteredBranches', branches);
    },
    filterBranches({commit, state}, e) {
        e.preventDefault();
        let form = e.target.closest('form'),
            keyword = form.querySelector('input[name="keyword"]'),
            help = form.querySelector('input[name="help"]'),
            filtered = false;
        if (keyword.value) {
            console.log("k");
            axios.get('/Company?Action=List Physios&Physio_Keyword='+keyword.value).then(response => {
                let fbranches = response.data.Branches;
                if(help.value){
                    console.log("&&h");
                    fbranches = fbranches.filter((branch) => {
                        return branch.Tags.includes(help.value);
                    });
                }
                filtered = true;
                commit('setFilteredBranches', fbranches);
                commit('setShowEmpty', true);
            });
        } else {
            if (help.value) {
                console.log("!k && h");
                let fbranches = state.branches;
                fbranches = fbranches.filter((branch) => {
                    return branch.Tags.includes(help.value);
                });
                filtered = true;
                commit('setFilteredBranches', fbranches);
                commit('setShowEmpty', true);
            }
        }

        if (!filtered) {
            commit('setFilteredBranches', state.branches);
            commit('setShowEmpty', false);
        }
    },
    updateSelectedBranch({dispatch, commit}, branch) {
        let locations = state.locations,
            loaded = locations.filter((find) => {
                return find.ID === branch.ID;
            });

        if (loaded.length === 0) {
            dispatch('fetchSingleLocation', branch);
        }

        if (state.selectedBranch === branch) {
            commit('setSelectedBranch', null);
        } else {
            commit('setSelectedBranch', branch);
        }
    },
    updateUserAddress({commit}, address) {
        commit('setUserAddress', address);
    },
    async fetchSingleLocation({commit}, branch) {
        const response = await axios.get('/Company?Action=View a Physio&CompanyProfile_id=' + branch.ID);
        if (response.data && response.data.ID > 0) {
            let locations = state.locations;
            locations.push(response.data);
            commit('setLocations', locations);
        }
    }
};

const mutations = {
    setBranches: (state, value) => (state.branches = value),
    setFilteredBranches: (state, value) => (state.filteredBranches = value),
    setLoaded: (state, value) => (state.loaded = value),
    setSelectedBranch: (state, value) => (state.selectedBranch = value),
    setUserAddress: (state, value) => (state.userAddress = value),
    setHelpList: (state, value) => (state.helpList = value),
    setShowEmpty: (state, value) => (state.allowEmptyResults = value),
    setLocations: (state, value) => (state.locations = value)
};

export default {
    state,
    getters,
    actions,
    mutations
};